/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import PropTypes from "prop-types";
import React, { useRef, useState, useEffect } from "react";

import Grid from "@components/Grid";
import Header from "@components/Header";
import CookiePopup from "@components/CookiePopup";
import GoodVibesForm from "@components/FormGoodVibes";
import FormJob from "@components/FormJob";
import FormBusiness from "@components/FormBusiness";

import { Provider as ModalProvider } from "@components/ModalContext";

import Modal from "@components/Modal";
//import NewsletterPopup from "@components/NewsletterPopup";

import WarningBanner from "@components/WarningBanner";

const Layout = ({ children, location }) => {
  const cursor = useRef(null);

  const [isIE, setIsIE] = useState();

  useEffect(() => {
    // detect IE11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;
    setIsIE(isIE);
  }, []);

  /* const onPopupClose = () => {
    document.cookie = "popupShown=true;path=/";
    setShowPopup(false);
  }; */

  const [isOpen, setIsOpen] = useState({ isOpen: false, typeForm: "" });

  return (
    <ModalProvider value={{ isOpen, setIsOpen }}>
      <Modal isOpen={isOpen}>
        {isOpen.typeForm === "business" && <FormBusiness />}
        {isOpen.typeForm === "job" && <FormJob />}
        {isOpen.typeForm === "contact" && <GoodVibesForm />}
      </Modal>

      {isIE && <WarningBanner ie11 />}

      {location.pathname !== "/" && <Header />}

      {children}

      {/*
            <CSSTransition
              in={showPopup}
              timeout={1000}
              classNames="newsletter-popup"
              mountOnEnter
              unmountOnExit
            >
              <NewsletterPopup onClose={onPopupClose} />
            </CSSTransition>
      <CookiePopup />
      */}

      <Grid.Debugger />
    </ModalProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
